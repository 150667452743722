import { MessageAlert } from '../global/types';
import { EnumLiteralsOf, Reducer } from '../../globals';

import actions from './sendMailActions';

export enum SendMailActionTypes {
  SEND_MAIL_CONFIRMATION_REQUEST = '@sendMail/SEND_MAIL_CONFIRMATION_REQUEST',
  SEND_MAIL_CONFIRMATION_SUCCESS = '@sendMail/SEND_MAIL_CONFIRMATION_SUCCESS',
  SEND_MAIL_CONFIRMATION_FAIL = '@sendMail/SEND_MAIL_CONFIRMATION_FAIL',
  SEND_MAIL_MESSAGE_CLOSE_ALERT = '@sendMail/SEND_MAIL_MESSAGE_CLOSE_ALERT',
}

export type SendMailConfirmation = {
  id: string;
  userId: string;
  paymentLinkId: string;
  name: string;
  to: string;
};

export type Envelope = {
  from: string;
  to: string[];
};

export interface SendMail {
  accepted: string[];
  rejected: string[];
  envelopeTime: number;
  messageTime: number;
  messageSize: number;
  response: string;
  envelope: Envelope;
  messageId: string;
}

export interface SendMailResponse {
  sendMailConfirmation: SendMail;
}

export type SendMailState = {
  confirmation: {
    isLoading?: boolean;
    error?: Error;
    messageAlert: MessageAlert;
  };
};

export type SendMailAction = ReturnType<EnumLiteralsOf<typeof actions>>;

export type SendMailReducer = Reducer<SendMailState, SendMailAction>;
