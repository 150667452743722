import {
  Company,
  LinkGeneratorActionTypes,
  PaymentLink,
  TransactionInput,
  User,
} from './types';

const loadUserByIdRequest = (payload: { userId: string }) => ({
  type: LinkGeneratorActionTypes.LOAD_USER_BY_ID_REQUEST,
  payload: { userId: payload.userId },
});

const loadUserByIdSuccess = (payload: { data: User }) => ({
  type: LinkGeneratorActionTypes.LOAD_USER_BY_ID_SUCCESS,
  payload: { data: payload.data },
});

const loadUserByIdFail = (payload: { error: Error }) => ({
  type: LinkGeneratorActionTypes.LOAD_USER_BY_ID_FAIL,
  payload: { error: payload.error },
});

const loadCompaniesRequest = () => ({
  type: LinkGeneratorActionTypes.LOAD_COMPANIES_REQUEST,
});

const loadCompaniesSuccess = (payload: { data: Company[] }) => ({
  type: LinkGeneratorActionTypes.LOAD_COMPANIES_SUCCESS,
  payload: { data: payload.data },
});

const loadCompaniesFail = (payload: { error: Error }) => ({
  type: LinkGeneratorActionTypes.LOAD_COMPANIES_FAIL,
  payload: { error: payload.error },
});

const createPaymentLinkRequest = (payload: {
  transactionInput: TransactionInput;
}) => ({
  type: LinkGeneratorActionTypes.CREATE_PAYMENT_LINK_REQUEST,
  payload: { transactionInput: payload.transactionInput },
});

const createPaymentLinkSuccess = (payload: { data: PaymentLink }) => ({
  type: LinkGeneratorActionTypes.CREATE_PAYMENT_LINK_SUCCESS,
  payload: { data: payload.data },
});

const createPaymentLinkFail = (payload: { error: Error }) => ({
  type: LinkGeneratorActionTypes.CREATE_PAYMENT_LINK_FAIL,
  payload: { error: payload.error },
});

const loadTransactionsRequest = () => ({
  type: LinkGeneratorActionTypes.LOAD_TRANSACTIONS_REQUEST,
});

const clearPaymentLinkRequest = () => ({
  type: LinkGeneratorActionTypes.CLEAR_PAYMENT_LINK_REQUEST,
});

const loadTransactionsSuccess = (payload: { data: TransactionInput[] }) => ({
  type: LinkGeneratorActionTypes.LOAD_TRANSACTIONS_SUCCESS,
  payload: { data: payload.data },
});

const loadTransactionsFail = (payload: { error: Error }) => ({
  type: LinkGeneratorActionTypes.LOAD_TRANSACTIONS_FAIL,
  payload: { error: payload.error },
});

const linkGeneratorActions = {
  loadUserByIdRequest,
  loadUserByIdSuccess,
  loadUserByIdFail,
  loadCompaniesRequest,
  loadCompaniesSuccess,
  loadCompaniesFail,
  createPaymentLinkRequest,
  createPaymentLinkSuccess,
  createPaymentLinkFail,
  loadTransactionsRequest,
  loadTransactionsSuccess,
  loadTransactionsFail,
  clearPaymentLinkRequest,
};

export default linkGeneratorActions;
