import { Store, createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';

import { PersistPartial } from 'redux-persist/es/persistReducer';
import rootReducer from './rootReducer';
import rootSagas from './rootSagas';
import persistReducers from './persistReducers';
// import { ApplicationState } from './types';

const sagaMiddleware = createSagaMiddleware();

const store: Store<PersistPartial> = createStore(
  persistReducers(rootReducer),
  composeWithDevTools(applyMiddleware(sagaMiddleware)),
);

export type ApplicationDispatch = typeof store.dispatch;

const persistor = persistStore(store);

sagaMiddleware.run(rootSagas);

export { store, persistor };
