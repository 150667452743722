import { client } from '../../utils/apolloClient';
import { getAllTransactionsQuery } from './postbackQueries';
import { GetAllTransactionsPostbackResponse, PaginationParams } from './types';

class PostbackRepository {
  getAllTransactions = async ({
    limit,
    page,
    companyId,
  }: PaginationParams): Promise<GetAllTransactionsPostbackResponse> => {
    const { data } = await client.query({
      fetchPolicy: 'network-only',
      query: getAllTransactionsQuery,
      variables: { limit, page, companyId },
    });

    return data;
  };
}

const postbackRepository = new PostbackRepository();

export default postbackRepository;
