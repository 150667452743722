import { all } from 'redux-saga/effects';
import postbackSagas from './postback/postbackSagas';
import sendMailSagas from './sendMail/sendMailSagas';
import sessionSagas from './session/sessionSagas';

import linkGeneratorSagas from './linkGenerator/sagas';

function* rootSagas() {
  yield all([
    ...postbackSagas,
    ...sendMailSagas,
    ...sessionSagas,
    ...linkGeneratorSagas,
  ]);
}

export default rootSagas;
