/* eslint-disable no-param-reassign */
/* eslint-disable eqeqeq */
export const validateCPF = (cpf: string) => {
  cpf = cpf.replace(/\D/g, '');
  if (cpf.toString().length != 11 || /^(\d)\1{10}$/.test(cpf)) return false;
  let result = true;
  [9, 10].forEach(j => {
    let soma = 0;
    let r: any;
    cpf
      .split(/(?=)/)
      .splice(0, j)
      .forEach((e, i) => {
        soma += parseInt(e, 10) * (j + 2 - (i + 1));
      });
    r = soma % 11;
    r = r < 2 ? 0 : 11 - r;
    if (r != cpf.substring(j, j + 1)) result = false;
  });
  return result;
};

export const validateEmail = (email: string) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const isValidExpirationDate = (expirationDate: string) => {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1; // getMonth() retorna mês de 0-11
  const currentYear = currentDate.getFullYear() % 100; // pega os dois últimos dígitos do ano

  const [expMonth, expYear] = expirationDate.split('/').map(Number);

  if (expMonth < 1 || expMonth > 12) return false;
  if (expYear < currentYear || expYear > currentYear + 20) return false;
  if (expYear === currentYear && expMonth < currentMonth) return false;

  return true;
};

export const validatePhone = (phone: string) => {
  const regex = /^[1-9]{2} 9[0-9]{4}-?[0-9]{4}$/;
  return regex.test(phone);
};
