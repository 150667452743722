import styled from 'styled-components';
import { rem, transparentize } from 'polished';
import { kick } from '../../utils/keyframes';

const StyledLoading = styled.div`
  color: #fff;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 5;
  background-color: ${transparentize(0.25, '#fff')};

  &:after,
  &:before {
    display: block;
    content: '';
    width: ${rem(20)};
    height: ${rem(20)};
    position: absolute;
    top: calc(50% - ${rem(10)});
    left: calc(50% - ${rem(10)});
    border-radius: 50%;
    background-color: #e91e63;
    animation: ${kick} 0.6s infinite alternate;
  }

  &:after {
    margin-left: ${rem(-30)};
    animation: ${kick} 0.6s infinite alternate;
  }

  &:before {
    animation-delay: 0.2s;
  }
`;

export { StyledLoading };
