import { takeLatest, put, call } from 'redux-saga/effects';
import { LinkGeneratorAction, LinkGeneratorActionTypes } from './types';
import linkGeneratorActions from './actions';
import linkGeneratorRepository from './repository';

export function* getUserByIdRequest(action: LinkGeneratorAction): any {
  const { payload } = action as ReturnType<
    typeof linkGeneratorActions.loadUserByIdRequest
  >;

  try {
    if (!payload) return;

    const { userId } = payload;

    const userIdFormatted = `55${userId}@c.us`;

    const getUserByUserId = yield call(
      linkGeneratorRepository.getUserById,
      userIdFormatted,
    );

    yield put(
      linkGeneratorActions.loadUserByIdSuccess({
        data: getUserByUserId,
      }),
    );
  } catch (error: any) {
    yield put(linkGeneratorActions.loadUserByIdFail({ error }));
  }
}

export function* getCompaniesRequest(): any {
  try {
    const companies = yield call(linkGeneratorRepository.searchAllCompanies);

    yield put(
      linkGeneratorActions.loadCompaniesSuccess({
        data: companies,
      }),
    );
  } catch (error: any) {
    yield put(linkGeneratorActions.loadCompaniesFail({ error }));
  }
}

export function* createPaymentLinkRequest(action: LinkGeneratorAction): any {
  const { payload } = action as ReturnType<
    typeof linkGeneratorActions.createPaymentLinkRequest
  >;

  try {
    if (!payload) return;

    const { transactionInput } = payload;

    const paymentLink = yield call(
      linkGeneratorRepository.createPaymentLink,
      transactionInput,
    );

    yield put(
      linkGeneratorActions.createPaymentLinkSuccess({
        data: { ...paymentLink, step: 1 },
      }),
    );

    yield call(linkGeneratorRepository.getTransactions);
  } catch (error: any) {
    yield put(linkGeneratorActions.createPaymentLinkFail({ error }));
  }
}

export function* getTransactionsRequest(): any {
  try {
    const transactions = yield call(linkGeneratorRepository.getTransactions);

    yield put(
      linkGeneratorActions.loadTransactionsSuccess({
        data: transactions,
      }),
    );
  } catch (error: any) {
    yield put(linkGeneratorActions.loadTransactionsFail({ error }));
  }
}

const linkGeneratorSagas = [
  takeLatest(
    LinkGeneratorActionTypes.LOAD_USER_BY_ID_REQUEST,
    getUserByIdRequest,
  ),
  takeLatest(
    LinkGeneratorActionTypes.LOAD_COMPANIES_REQUEST,
    getCompaniesRequest,
  ),
  takeLatest(
    LinkGeneratorActionTypes.CREATE_PAYMENT_LINK_REQUEST,
    createPaymentLinkRequest,
  ),
  takeLatest(
    LinkGeneratorActionTypes.LOAD_TRANSACTIONS_REQUEST,
    getTransactionsRequest,
  ),
];

export default linkGeneratorSagas;
