import { EnumLiteralsOf, Reducer } from '../../globals';

import actions from './actions';

export enum LinkGeneratorActionTypes {
  LOAD_USER_BY_ID_REQUEST = '@link/LOAD_USER_BY_ID_REQUEST',
  LOAD_USER_BY_ID_SUCCESS = '@link/LOAD_USER_BY_ID_SUCCESS',
  LOAD_USER_BY_ID_FAIL = '@link/LOAD_USER_BY_ID_FAIL',
  LOAD_COMPANIES_REQUEST = '@link/LOAD_COMPANIES_REQUEST',
  LOAD_COMPANIES_SUCCESS = '@link/LOAD_COMPANIES_SUCCESS',
  LOAD_COMPANIES_FAIL = '@link/LOAD_COMPANIES_FAIL',
  CREATE_PAYMENT_LINK_REQUEST = '@link/CREATE_PAYMENT_LINK_REQUEST',
  CREATE_PAYMENT_LINK_SUCCESS = '@link/CREATE_PAYMENT_LINK_SUCCESS',
  CREATE_PAYMENT_LINK_FAIL = '@link/CREATE_PAYMENT_LINK_FAIL',
  CLEAR_PAYMENT_LINK_REQUEST = '@link/CLEAR_PAYMENT_LINK_REQUEST',
  LOAD_TRANSACTIONS_REQUEST = '@link/LOAD_TRANSACTIONS_REQUEST',
  LOAD_TRANSACTIONS_SUCCESS = '@link/LOAD_TRANSACTIONS_SUCCESS',
  LOAD_TRANSACTIONS_FAIL = '@link/LOAD_TRANSACTIONS_FAIL',
}

export type User = {
  _id: string;
  name: string;
  cpf: string;
  email: string;
  phone: string;
};

export type Company = {
  _id: string;
  name: string;
  cnpj: string;
  phone: string;
};

export type Items = {
  id?: string;
  title: string;
  quantity: number;
  tangible?: boolean;
  category: string;
  unit_price: number;
};

export type PaymentLink = {
  step: number;
  id: string;
  url: string;
};

export interface GetUserIdPromise {
  getUserByUserId: User;
}

export interface CompaniesPromise {
  searchAllCompanies: Company[];
}

export interface TransactionsPromise {
  getTransactions: TransactionInput[];
}

export interface CreatePaymentLinkPromise {
  createPaymentLink: PaymentLink;
}

export type TransactionsState = {
  dataList: TransactionInput[];
  isLoading?: boolean;
  error?: Error;
};

export type CompaniesState = {
  dataList: Company[];
  isLoading?: boolean;
  error?: Error;
};

export type UserState = {
  dataList: User;
  isLoading?: boolean;
  error?: Error;
};

export type PaymentLinkState = {
  dataList: PaymentLink;
  isLoading?: boolean;
  error?: Error;
};

export type LinkGeneratorState = {
  user: UserState;
  companies: CompaniesState;
  paymentLink: PaymentLinkState;
  transactions: TransactionsState;
};

export type TransactionInput = {
  name: string;
  amount: number;
  group: string;
  subgroup: string;
  company?: Company;
  customer: User;
  payment_config: {
    boleto: {
      enabled: boolean;
      expires_in: number;
    };
    credit_card: {
      enabled: boolean;
      free_installments: number;
      interest_rate: number;
      max_installments: number;
    };
    pix: {
      enabled: boolean;
    };
  };
  paymentLink?: {
    id: string;
    url: string;
  };
  max_orders: number;
  items: Items[];
  expires_in: number;
  user: string;
  createdAt?: string;
};

export type LinkGeneratorAction = ReturnType<EnumLiteralsOf<typeof actions>>;

export type LinkGeneratorReducer = Reducer<
  LinkGeneratorState,
  LinkGeneratorAction
>;
