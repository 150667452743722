import { keyframes } from 'styled-components';
import { rem } from 'polished';

const rotate = keyframes`
  from { transform: rotate(0) }
  to { transform: rotate(359deg) }
`;

const kick = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: .3;
    transform: translateY(${rem(-1)};)
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const slideUp = keyframes`
  from {
    opacity: .3;
    transform: translateY(${rem(20)});
  }
  to {
    opacity: 1;
    transform: translateY(0)
  }
`;

export { rotate, kick, fadeIn, slideUp };
