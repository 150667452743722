import { ErrorResponse } from '@apollo/client/link/error';
import { get } from 'lodash';
import Environments, { defaultEnvironmentKey } from './environments';

export const getStoredAuthToken = (): string => {
  const token = localStorage.getItem('authToken');
  return token && JSON.parse(token);
};

export const getStoredRefreshToken = (): string => {
  const refreshToken = localStorage.getItem('authRefreshToken');
  return refreshToken && JSON.parse(refreshToken);
};

export const removeStoredAuthToken = (): void => {
  localStorage.removeItem('authToken');
};

export const storeAuthRefreshToken = (refreshToken: string): void => {
  localStorage.setItem('authRefreshToken', JSON.stringify(refreshToken));
};

export const storeAuthToken = (token: string): void => {
  localStorage.setItem('authToken', JSON.stringify(token));
};

export const saveEnvironmentKey = (env: string): void => {
  localStorage.setItem('envName', env);
};

export const getEnvironmentKey = (): string => {
  const envKey = localStorage.getItem('envName');

  if (!envKey || !Environments[envKey]) {
    return defaultEnvironmentKey;
  }

  return envKey;
};

export const isAuthenticated = (): boolean => {
  const token: string = getStoredAuthToken();
  return !!token;
};

export const getErrorMessage = (graphQLErrors: ErrorResponse) => {
  const errorCode = get(graphQLErrors, [0, 'name']);
  const textError = get(graphQLErrors, [0, 'message']);

  return errorCode === 'INTERNAL_SERVER_ERROR'
    ? 'Ocorreu um erro ao tentar processar a operação'
    : textError;
};
