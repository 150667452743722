import ReactGA from 'react-ga4';
import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { HashRouter as Router, Switch } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { PersistGate } from 'redux-persist/integration/react';
import { client } from './utils/apolloClient';
import routes from './routes';
import RouteWithSubRoutes from './routes/routeWithSubRoutes';

import { store, persistor } from './store';

import Loading from './components/Loading';
import './styles/global/material-dashboard.css?v=1.10.0';

const TRACKING_ID = 'G-DD7J3N3YJE';

ReactGA.initialize(TRACKING_ID);

const App: React.FC = () => (
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <ApolloProvider client={client}>
        <Router>
          <Suspense fallback={<Loading />}>
            <Switch>
              {routes.map(route => (
                <RouteWithSubRoutes key={route.path} {...route} />
              ))}
            </Switch>
          </Suspense>
        </Router>
      </ApolloProvider>
    </PersistGate>
  </Provider>
);

export default App;
