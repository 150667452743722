import { gql } from '@apollo/client';

export const sendMailConfirmationMutation = gql`
  mutation emailTrigger(
    $userId: String!
    $paymentLinkId: String!
    $name: String!
    $to: String!
  ) {
    emailTrigger(
      userId: $userId
      paymentLinkId: $paymentLinkId
      name: $name
      to: $to
    ) {
      accepted
      rejected
      envelopeTime
      messageTime
      messageSize
      response
      messageId
      envelope {
        from
        to
      }
    }
  }
`;
