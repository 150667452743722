import { gql } from '@apollo/client';

export const getUserByUserIdQuery = gql`
  query getUserByUserId($userId: String!) {
    getUserByUserId(userId: $userId) {
      _id
      name
      cpf
      email
      phone
    }
  }
`;

export const searchAllCompaniesQuery = gql`
  {
    searchAllCompanies {
      _id
      name
      cnpj
      phone
    }
  }
`;

export const createPaymentLinkMutation = gql`
  mutation createPaymentLink($transactionInput: TransactionInput!) {
    createPaymentLink(paymentData: $transactionInput) {
      id
      url
    }
  }
`;

export const getTransactionsQuery = gql`
  {
    getTransactions {
      name
      amount
      group
      subgroup
      company {
        _id
        name
        cnpj
        phone
      }
      customer {
        _id
        name
        cpf
        email
        phone
      }
      payment_config {
        boleto {
          enabled
          expires_in
        }
        credit_card {
          enabled
          free_installments
          interest_rate
          min_installments
          max_installments
          charge_transaction_fee
        }
        pix {
          enabled
        }
      }
      items {
        id
        title
        quantity
        category
        unit_price
      }
      paymentLink {
        id
        url
      }
      expires_in
      user
      createdAt
    }
  }
`;
