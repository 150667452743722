import { MessageAlert } from '../global/types';
import { SendMailActionTypes } from './types';

const loadSendMailConfirmationRequest = (payload: {
  id: string;
  userId: string;
  paymentLinkId: string;
  name: string;
  to: string;
}) => ({
  type: SendMailActionTypes.SEND_MAIL_CONFIRMATION_REQUEST,
  payload: {
    id: payload.id,
    userId: payload.userId,
    paymentLinkId: payload.paymentLinkId,
    name: payload.name,
    to: payload.to,
  },
});

const loadSendMailConfirmationSuccess = (payload: {
  messageAlert: MessageAlert;
}) => ({
  type: SendMailActionTypes.SEND_MAIL_CONFIRMATION_SUCCESS,
  payload: { messageAlert: payload.messageAlert },
});

const loadSendMailConfirmationFail = (payload: {
  error: Error;
  messageAlert: MessageAlert;
}) => ({
  type: SendMailActionTypes.SEND_MAIL_CONFIRMATION_FAIL,
  payload: { error: payload.error, messageAlert: payload.messageAlert },
});

const setCloseMessageAlert = (payload: { closeAlert: boolean }) => ({
  type: SendMailActionTypes.SEND_MAIL_MESSAGE_CLOSE_ALERT,
  payload: { closeAlert: payload.closeAlert },
});

const sendMailActions = {
  loadSendMailConfirmationRequest,
  loadSendMailConfirmationSuccess,
  loadSendMailConfirmationFail,
  setCloseMessageAlert,
};

export default sendMailActions;
