import { MessageAlert } from '../global/types';
import { EnumLiteralsOf, Reducer } from '../../globals';

import actions from './sessionActions';

export enum CreateSessionActionTypes {
  CREATE_SESSION_REQUEST = '@session/CREATE_SESSION_REQUEST',
  CREATE_SESSION_SUCCESS = '@session/CREATE_SESSION_SUCCESS',
  CREATE_SESSION_FAIL = '@session/CREATE_SESSION_FAIL',
  MESSAGE_CLOSE_ALERT = '@session/MESSAGE_CLOSE_ALERT',
}

export type CreateSession = {
  _id: string;
  name: string;
  email: string;
  roles: string[];
  token: string;
  refreshToken: string;
};

export type CreateSessionResponse = {
  createSession: CreateSession;
};

export type RefreshTokenResponse = {
  token: string;
  refreshToken: string;
};

export type LoginFields = {
  email: string;
  password: string;
};

export type SessionState = {
  profile: CreateSession;
  isLoading?: boolean;
  error?: Error;
  messageAlert: MessageAlert;
};

export type SessionAction = ReturnType<EnumLiteralsOf<typeof actions>>;

export type SessionReducer = Reducer<SessionState, SessionAction>;
