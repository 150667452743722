import { gql } from '@apollo/client';

export const createSessionMutation = gql`
  mutation createSessionMutation($email: String!, $password: String!) {
    createSession(email: $email, password: $password) {
      _id
      name
      email
      roles
      token
      refreshToken
    }
  }
`;

export const refreshTokenMutation = gql`
  mutation refreshTokenMutation($token: String!) {
    refreshToken(token: $token) {
      _id
      name
      email
      roles
      token
      refreshToken
    }
  }
`;
