import _ from 'lodash';

import actions from './actions';
import {
  LinkGeneratorAction,
  LinkGeneratorActionTypes,
  LinkGeneratorReducer,
  LinkGeneratorState,
} from './types';

export const INITIAL_STATE: LinkGeneratorState = {
  user: {
    dataList: {
      _id: '',
      name: '',
      cpf: '',
      email: '',
      phone: '',
    },
    isLoading: false,
    error: undefined,
  },
  companies: {
    dataList: [],
    isLoading: false,
    error: undefined,
  },
  paymentLink: {
    dataList: {
      step: 0,
      id: '',
      url: '',
    },
    isLoading: false,
    error: undefined,
  },
  transactions: {
    dataList: [],
    isLoading: false,
    error: undefined,
  },
};

const loadUserByIdRequest: LinkGeneratorReducer = state => ({
  ...state,
  user: {
    ...state.user,
    isLoading: true,
  },
});

const loadUserByIdSuccess: LinkGeneratorReducer = (state, action) => {
  const { payload } = action as ReturnType<typeof actions.loadUserByIdSuccess>;

  return {
    ...state,
    user: {
      ...state.user,
      dataList: payload.data,
      isLoading: false,
    },
  };
};

const loadUserByIdFail: LinkGeneratorReducer = (state, action) => {
  const { payload } = action as ReturnType<typeof actions.loadUserByIdFail>;

  return {
    ...state,
    user: {
      ...state.user,
      error: payload.error,
      isLoading: false,
    },
  };
};

const loadCompaniesRequest: LinkGeneratorReducer = state => ({
  ...state,
  companies: {
    ...state.companies,
    isLoading: true,
  },
});

const loadCompaniesSuccess: LinkGeneratorReducer = (state, action) => {
  const { payload } = action as ReturnType<typeof actions.loadCompaniesSuccess>;

  return {
    ...state,
    companies: {
      ...state.companies,
      dataList: payload.data,
      isLoading: false,
    },
  };
};

const loadCompaniesFail: LinkGeneratorReducer = (state, action) => {
  const { payload } = action as ReturnType<typeof actions.loadCompaniesFail>;

  return {
    ...state,
    companies: {
      ...state.companies,
      error: payload.error,
      isLoading: false,
    },
  };
};

const createPaymentLinkRequest: LinkGeneratorReducer = state => ({
  ...state,
  paymentLink: {
    ...state.paymentLink,
    isLoading: true,
  },
});

const createPaymentLinkSuccess: LinkGeneratorReducer = (state, action) => {
  const { payload } = action as ReturnType<
    typeof actions.createPaymentLinkSuccess
  >;

  return {
    ...state,
    paymentLink: {
      ...state.paymentLink,
      dataList: payload.data,
      isLoading: false,
    },
  };
};

const createPaymentLinkFail: LinkGeneratorReducer = (state, action) => {
  const { payload } = action as ReturnType<
    typeof actions.createPaymentLinkFail
  >;

  return {
    ...state,
    paymentLink: {
      dataList: {
        step: 0,
        id: '',
        url: '',
      },
      error: payload.error,
      isLoading: false,
    },
  };
};

const clearPaymentLinkRequest: LinkGeneratorReducer = state => ({
  ...state,
  paymentLink: {
    dataList: {
      step: 0,
      id: '',
      url: '',
    },
    isLoading: false,
    error: undefined,
  },
});

const loadTransactionsRequest: LinkGeneratorReducer = state => ({
  ...state,
  transactions: {
    ...state.transactions,
    isLoading: true,
  },
});

const loadTransactionsSuccess: LinkGeneratorReducer = (state, action) => {
  const { payload } = action as ReturnType<
    typeof actions.loadTransactionsSuccess
  >;

  return {
    ...state,
    transactions: {
      ...state.transactions,
      dataList: payload.data,
      isLoading: false,
    },
  };
};

const loadTransactionsFail: LinkGeneratorReducer = (state, action) => {
  const { payload } = action as ReturnType<typeof actions.loadTransactionsFail>;

  return {
    ...state,
    transactions: {
      ...state.transactions,
      error: payload.error,
      isLoading: false,
    },
  };
};

const linkGeneratorReducers = {
  [LinkGeneratorActionTypes.LOAD_USER_BY_ID_REQUEST]: loadUserByIdRequest,
  [LinkGeneratorActionTypes.LOAD_USER_BY_ID_SUCCESS]: loadUserByIdSuccess,
  [LinkGeneratorActionTypes.LOAD_USER_BY_ID_FAIL]: loadUserByIdFail,
  [LinkGeneratorActionTypes.LOAD_COMPANIES_REQUEST]: loadCompaniesRequest,
  [LinkGeneratorActionTypes.LOAD_COMPANIES_SUCCESS]: loadCompaniesSuccess,
  [LinkGeneratorActionTypes.LOAD_COMPANIES_FAIL]: loadCompaniesFail,
  [LinkGeneratorActionTypes.CREATE_PAYMENT_LINK_REQUEST]:
    createPaymentLinkRequest,
  [LinkGeneratorActionTypes.CREATE_PAYMENT_LINK_SUCCESS]:
    createPaymentLinkSuccess,
  [LinkGeneratorActionTypes.CREATE_PAYMENT_LINK_FAIL]: createPaymentLinkFail,
  [LinkGeneratorActionTypes.CLEAR_PAYMENT_LINK_REQUEST]:
    clearPaymentLinkRequest,
  [LinkGeneratorActionTypes.LOAD_TRANSACTIONS_REQUEST]: loadTransactionsRequest,
  [LinkGeneratorActionTypes.LOAD_TRANSACTIONS_SUCCESS]: loadTransactionsSuccess,
  [LinkGeneratorActionTypes.LOAD_TRANSACTIONS_FAIL]: loadTransactionsFail,
};

const linkGeneratorReducer = (
  state: LinkGeneratorState = INITIAL_STATE,
  action: LinkGeneratorAction,
): LinkGeneratorState => {
  const reducer = _.find(
    linkGeneratorReducers,
    (_value, key) => key === action.type,
  );

  if (reducer) return reducer(state, action);

  return state;
};

export default linkGeneratorReducer;
