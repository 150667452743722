/* eslint-disable import/no-duplicates */
import { call, put, takeLatest } from 'redux-saga/effects';
import EmailIcon from '@material-ui/icons/Email';

import { store } from '..';
import { SendMailConfirmation } from '../sendMail/types';
import { formatPrice } from '../../utils/formatCurrency';
import { validateEmail } from '../../utils/validateFields';
import postbackActions from './postbackActions';
import sendMailActions from '../sendMail/sendMailActions';
import postbackRepository from './postbackRepository';
import {
  PostbackAction,
  PostbackActionTypes,
  PostbackTransaction,
} from './types';

const handleSendEmailConfirmation = ({
  id,
  userId,
  paymentLinkId,
  name,
  to,
}: SendMailConfirmation) => {
  store.dispatch(
    sendMailActions.loadSendMailConfirmationRequest({
      id,
      userId,
      paymentLinkId,
      name,
      to,
    }),
  );
};

export function* getAllTransactionsRequest(action: PostbackAction): any {
  const { payload } = action as ReturnType<
    typeof postbackActions.loadAllTransactionsRequest
  >;

  try {
    if (!payload) return;

    const { limit, page, companyId } = payload;

    const { getAllTransactions } = yield call(
      postbackRepository.getAllTransactions,
      {
        limit,
        page,
        companyId,
      },
    );

    const docFormatted = getAllTransactions.docs.map(
      (doc: PostbackTransaction) => {
        const {
          id,
          companyName,
          current_status,
          transaction,
          user,
          items,
          paymentLinkId,
          logEmail,
          amountFees,
        } = doc;

        const name = (user && user.name) || '';
        const userId = user && user.userId;
        const phone = user && user.phone;
        const instructor = user && user.instructor;
        const cpf = user && user.cpf;
        const email = (user && user.email) || '';
        const namePayer = transaction.customer.name;
        const cpfPayer = transaction.customer.documents[0].number;
        const sended = logEmail && logEmail.sended;
        const amountFeesFormatted = formatPrice(amountFees || 0);
        const amountFormatted = formatPrice(transaction.amount);

        const emailIsValid = validateEmail(email)
          ? email
          : 'E-mail informado inválido';

        const canSendEmail = !!paymentLinkId && !!email && !sended;
        const activeIconEmail = sended;

        const tableActions = {
          activeIcon: !activeIconEmail,
          disabled: !canSendEmail,
          iconActive: EmailIcon,
          iconInactive: EmailIcon,
          onClick: () => {
            handleSendEmailConfirmation({
              id,
              userId,
              paymentLinkId,
              name,
              to: email,
            });
          },
        };

        return {
          tableActions,
          id,
          companyName,
          name,
          cpf,
          items,
          instructor,
          phone,
          emailIsValid,
          namePayer,
          cpfPayer,
          amountFeesFormatted,
          amountFormatted,
          current_status,
          paymentMethod: transaction.payment_method,
          installments: transaction.installments,
        };
      },
    );

    yield put(
      postbackActions.loadAllTransactionsSuccess({
        data: {
          docs: docFormatted,
          pagination: getAllTransactions.pagination,
        },
      }),
    );
  } catch (error: any) {
    yield put(postbackActions.loadAllTransactionsFail({ error }));
  }
}

const postbackSagas = [
  takeLatest(
    PostbackActionTypes.LOAD_ALL_TRANSACTIONS_REQUEST,
    getAllTransactionsRequest,
  ),
];

export default postbackSagas;
