export enum GlobalActionTypes {
  CLEAR_APP_STATE = '@global/CLEAR_APP_STATE',
}

export type GlobalAction = {
  type: GlobalActionTypes;
  payload?: any;
};

export enum MessageAlertTypes {
  SUCCESS = 'success',
  ERROR = 'error',
  INFO = 'info',
  WARN = 'warning',
}

export type MessageAlert = {
  type?: MessageAlertTypes;
  message?: string;
  open: boolean;
};
