import _ from 'lodash';
import {
  PostbackAction,
  PostbackActionTypes,
  PostbackReducer,
  PostbackState,
} from './types';

import actions from './postbackActions';

export const INITIAL_STATE: PostbackState = {
  transactions: {
    dataList: {
      docs: [],
      pagination: {
        pages: 0,
        page: 0,
        totalDocs: 0,
      },
    },
    isLoading: false,
    error: undefined,
  },
};

const loadAllTransactionsRequest: PostbackReducer = state => ({
  ...state,
  transactions: {
    ...state.transactions,
    isLoading: true,
  },
});

const loadAllTransactionsSuccess: PostbackReducer = (state, action) => {
  const { payload } = action as ReturnType<
    typeof actions.loadAllTransactionsSuccess
  >;

  return {
    ...state,
    transactions: {
      dataList: payload.data,
      isLoading: false,
    },
  };
};

const loadAllTransactionsFail: PostbackReducer = (state, action) => {
  const { payload } = action as ReturnType<
    typeof actions.loadAllTransactionsFail
  >;

  return {
    ...state,
    transactions: {
      ...state.transactions,
      error: payload.error,
      isLoading: false,
    },
  };
};

const postbackReducers = {
  [PostbackActionTypes.LOAD_ALL_TRANSACTIONS_REQUEST]:
    loadAllTransactionsRequest,
  [PostbackActionTypes.LOAD_ALL_TRANSACTIONS_SUCCESS]:
    loadAllTransactionsSuccess,
  [PostbackActionTypes.LOAD_ALL_TRANSACTIONS_FAIL]: loadAllTransactionsFail,
};

const postbackReducer = (
  state: PostbackState = INITIAL_STATE,
  action: PostbackAction,
): PostbackState => {
  const reducer = _.find(
    postbackReducers,
    (_value, key) => key === action.type,
  );

  if (reducer) return reducer(state, action);

  return state;
};

export default postbackReducer;
