/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-use-before-define */
import {
  ApolloClient,
  HttpLink,
  ApolloLink,
  InMemoryCache,
  from,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { RefreshTokenResponse } from '../store/session/types';

import Environments from './environments';
import {
  getStoredAuthToken,
  getStoredRefreshToken,
  removeStoredAuthToken,
  getEnvironmentKey,
  storeAuthToken,
  storeAuthRefreshToken,
} from './helpers';
import { refreshTokenMutation } from '../store/session/sessionQueries';

const envKey = getEnvironmentKey();
const environment = Environments[envKey];
const httpLink = new HttpLink({
  uri: environment.endpoint,
});

const refreshAuthToken = async (
  refreshToken: string,
): Promise<RefreshTokenResponse | null | undefined> => {
  const response = await client.mutate({
    mutation: refreshTokenMutation,
    variables: { token: refreshToken },
  });

  if (response && response.data && response.data.refreshToken) {
    const { token, refreshToken: newRefreshToken } = response.data.refreshToken;
    storeAuthToken(token);
    storeAuthRefreshToken(newRefreshToken);
    return { token, refreshToken: newRefreshToken };
  }

  return null;
};

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      const isAuthError = graphQLErrors
        ? graphQLErrors.find(
            error => error.extensions?.exception?.name === 'TokenExpiredError',
          )
        : false;

      const hasStoredAuthToken = !!getStoredAuthToken();

      if (isAuthError && hasStoredAuthToken) {
        const refreshToken = getStoredRefreshToken();

        if (!refreshToken) {
          removeStoredAuthToken();
          window.location.reload();
          return;
        }

        refreshAuthToken(refreshToken)
          .then(response => {
            if (response && response.token && response.refreshToken) {
              const { token, refreshToken: newRefreshToken } = response;
              storeAuthToken(token);
              storeAuthRefreshToken(newRefreshToken);
              operation.setContext(({ headers = {} }) => ({
                headers: {
                  ...headers,
                  Authorization: token || '',
                },
              }));
              return forward(operation);
            }
            removeStoredAuthToken();
            window.location.reload();
          })
          .catch(() => {
            removeStoredAuthToken();
            window.location.reload();
          });
      } else {
        //  Lidar com erros do GraphQL
      }
    }

    if (networkError) {
      console.error(`[Network error]: ${networkError}`);
    }
  },
);

const authMiddleware = new ApolloLink((operation, forward) => {
  const token = getStoredAuthToken();

  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      Authorization: token || '',
    },
  }));

  return forward(operation);
});

export const client = new ApolloClient({
  link: from([errorLink, authMiddleware, httpLink]),
  cache: new InMemoryCache({
    addTypename: false,
  }),
});
