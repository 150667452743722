import { lazy } from 'react';
import DashboardIcon from '@material-ui/icons/Dashboard';
import TransactionIcon from '@material-ui/icons/ReceiptRounded';
import LinkIcon from '@material-ui/icons/LinkOutlined';

const Home = lazy(() => import(/* webpackChunkName: 'home' */ '../pages/Home'));

const SignInSession = lazy(
  () => import(/* webpackChunkName: 'signin' */ '../pages/SignIn'),
);

const Dashboard = lazy(
  () => import(/* webpackChunkName: 'dashboard' */ '../pages/Dashboard'),
);

const Transactions = lazy(
  () =>
    import(
      /* webpackChunkName: 'Transactions' */ '../pages/Dashboard/Transactions'
    ),
);

const Report = lazy(
  () => import(/* webpackChunkName: 'Report' */ '../pages/Dashboard/Report'),
);

const Links = lazy(
  () => import(/* webpackChunkName: 'Links' */ '../pages/Dashboard/Links'),
);

const ServiceSubscription = lazy(
  () =>
    import(
      /* webpackChunkName: 'Service' */ '../pages/Subscription/subroutes/Steps'
    ),
);

const ConfigSubscription = lazy(
  () =>
    import(
      /* webpackChunkName: 'Service' */ '../pages/Subscription/subroutes/Configuration'
    ),
);

const routes = [
  {
    path: '/',
    name: 'Root',
    layout: '/admin',
    component: Home,
    isPrivate: false,
    exact: true,
  },
  {
    path: '/signin',
    name: 'Entrar',
    layout: '/admin',
    component: SignInSession,
    isPrivate: false,
    exact: true,
  },
  {
    path: '/subscription',
    name: 'Assinatura',
    layout: '/admin',
    component: ServiceSubscription,
    isPrivate: false,
  },
  {
    path: '/subscriptions/token=:subscriptionId',
    name: 'Configuração de Assinatura',
    layout: '/admin',
    component: ConfigSubscription,
    isPrivate: false,
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    layout: '/admin',
    component: Dashboard,
    isPrivate: true,
    routes: [
      {
        path: '/dashboard/report',
        name: 'Relatório',
        layout: '/admin',
        icon: DashboardIcon,
        component: Report,
        isPrivate: true,
        exact: true,
      },
      {
        path: '/dashboard/transactions',
        name: 'Transações',
        layout: '/admin',
        icon: TransactionIcon,
        component: Transactions,
        isPrivate: true,
        exact: true,
      },
      {
        path: '/dashboard/links',
        name: 'Links de Pagamento',
        layout: '/admin',
        icon: LinkIcon,
        component: Links,
        isPrivate: true,
        exact: true,
      },
    ],
  },
];

export default routes;
