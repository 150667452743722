import _ from 'lodash';
import actions from './sendMailActions';
import {
  SendMailAction,
  SendMailActionTypes,
  SendMailReducer,
  SendMailState,
} from './types';

export const INITIAL_STATE: SendMailState = {
  confirmation: {
    isLoading: false,
    error: undefined,
    messageAlert: {
      message: '',
      type: undefined,
      open: false,
    },
  },
};

const loadSendMailConfirmationRequest: SendMailReducer = state => ({
  ...state,
  confirmation: {
    ...state.confirmation,
    isLoading: true,
  },
});

const loadSendMailConfirmationSuccess: SendMailReducer = (state, action) => {
  const { payload } = action as ReturnType<
    typeof actions.loadSendMailConfirmationSuccess
  >;

  return {
    ...state,
    confirmation: {
      ...state.confirmation,
      isLoading: false,
      messageAlert: payload.messageAlert,
    },
  };
};

const loadSendMailConfirmationFail: SendMailReducer = (state, action) => {
  const { payload } = action as ReturnType<
    typeof actions.loadSendMailConfirmationFail
  >;

  return {
    ...state,
    confirmation: {
      ...state.confirmation,
      error: payload.error,
      isLoading: false,
      messageAlert: payload.messageAlert,
    },
  };
};

const setCloseMessageAlert: SendMailReducer = (state, action) => {
  const { payload } = action as ReturnType<typeof actions.setCloseMessageAlert>;

  return {
    ...state,
    confirmation: {
      ...state.confirmation,
      messageAlert: {
        message: '',
        type: undefined,
        open: payload.closeAlert,
      },
    },
  };
};

const sendMailReducers = {
  [SendMailActionTypes.SEND_MAIL_CONFIRMATION_REQUEST]:
    loadSendMailConfirmationRequest,
  [SendMailActionTypes.SEND_MAIL_CONFIRMATION_SUCCESS]:
    loadSendMailConfirmationSuccess,
  [SendMailActionTypes.SEND_MAIL_CONFIRMATION_FAIL]:
    loadSendMailConfirmationFail,
  [SendMailActionTypes.SEND_MAIL_MESSAGE_CLOSE_ALERT]: setCloseMessageAlert,
};

const sendMailReducer = (
  state: SendMailState = INITIAL_STATE,
  action: SendMailAction,
): SendMailState => {
  const reducer = _.find(
    sendMailReducers,
    (_value, key) => key === action.type,
  );

  if (reducer) return reducer(state, action);

  return state;
};

export default sendMailReducer;
