import { call, put, select, takeLatest } from 'redux-saga/effects';
import { MessageAlertTypes } from '../global/types';
import postbackActions from '../postback/postbackActions';
import postbackSelectors from '../postback/postbackSelectors';
import sendMailActions from './sendMailActions';
import sendMailRepository from './sendMailRepository';
import { SendMailAction, SendMailActionTypes } from './types';

export function* sendMailConfirmationRequest(action: SendMailAction): any {
  const { payload } = action as ReturnType<
    typeof sendMailActions.loadSendMailConfirmationRequest
  >;

  try {
    if (!payload) return;

    const { id, userId, paymentLinkId, name, to } = payload;

    const { sendMailConfirmation } = yield call(
      sendMailRepository.sendMailConfirmation,
      userId,
      paymentLinkId,
      name,
      to,
    );

    if (!sendMailConfirmation) {
      throw new Error('Não foi possivel enviar o e-mail de confirmação');
    }

    const { docs } = yield select(postbackSelectors.dataListTransactions);
    const pagination = yield select(postbackSelectors.paginationTransactions);

    const objIndex = docs.findIndex((doc: any) => doc.id === id);

    if (objIndex) {
      const { tableActions } = docs[objIndex];
      tableActions.activeIcon = false;
      tableActions.disabled = true;

      yield put(
        postbackActions.loadAllTransactionsSuccess({
          data: {
            docs,
            pagination,
          },
        }),
      );
    }

    yield put(
      sendMailActions.loadSendMailConfirmationSuccess({
        messageAlert: {
          message: 'E-mail enviado com sucesso!',
          type: MessageAlertTypes.SUCCESS,
          open: true,
        },
      }),
    );
  } catch (error: any) {
    yield put(
      sendMailActions.loadSendMailConfirmationFail({
        error: error.message,
        messageAlert: {
          message: error.message,
          type: MessageAlertTypes.ERROR,
          open: true,
        },
      }),
    );
  }
}

const sendMailSagas = [
  takeLatest(
    SendMailActionTypes.SEND_MAIL_CONFIRMATION_REQUEST,
    sendMailConfirmationRequest,
  ),
];

export default sendMailSagas;
