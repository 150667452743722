import { gql } from '@apollo/client';

export const getAllTransactionsQuery = gql`
  query getAllTransactionsQuery(
    $limit: Float!
    $page: Float!
    $companyId: String
  ) {
    getAllTransactions(limit: $limit, page: $page, companyId: $companyId) {
      docs {
        id
        companyName
        current_status
        paymentLinkId
        amountFees
        transaction {
          refuse_reason
          status_reason
          date_created
          date_updated
          amount
          paid_amount
          refunded_amount
          installments
          cost
          postback_url
          payment_method
          antifraud_score
          customer {
            id
            external_id
            type
            country
            name
            email
            phone_numbers
            documents {
              id
              type
              number
            }
          }
          billing {
            id
            name
            address {
              street
              complementary
              street_number
              neighborhood
              city
              state
              zipcode
              country
              id
            }
          }
          card {
            id
            brand
            holder_name
            first_digits
            last_digits
            country
            valid
            expiration_date
          }
          fraud_covered
          fraud_reimbursed
          order_id
          risk_level
          receipt_url
          payment
          addition
          pix_qr_code
          pix_expiration_date
        }
        items {
          id
          title
          quantity
          category
          unit_price
        }
        user {
          userId
          name
          email
          cpf
          phone
          emergencyPhone
          bloodType
          battery
          allergy
          instructor
          companion
          address
          motorcycle
        }
        logEmail {
          sended
        }
      }
      pagination {
        page
        pages
        totalDocs
      }
    }
  }
`;
