export interface EnvBase {
  name: string;
  url: string;
  socketUrl: string;
}

export interface Environment extends EnvBase {
  endpoint: string;
}

const composeEnviroment = ({ name, url, socketUrl }: EnvBase): Environment => ({
  name,
  url,
  socketUrl,
  endpoint: `${url}/graphql`,
});

const development: Environment = composeEnviroment({
  name: 'Development',
  url: 'http://localhost:9000/development',
  socketUrl: 'http://localhost:8443',
});

const production: Environment = composeEnviroment({
  name: 'PROD',
  url: 'https://prd-app.easytrackpay.com',
  socketUrl: '',
});

const Environments: { [key: string]: Environment } = {
  development,
  production,
};

export const defaultEnvironmentKey: string =
  process.env.NODE_ENV || 'development';

export const defaultEnvironment = Environments[defaultEnvironmentKey];

export default Environments;
