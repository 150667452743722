/* eslint-disable no-shadow */
import { SvgIconTypeMap } from '@material-ui/core';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { EnumLiteralsOf, Reducer } from '../../globals';

import actions from './postbackActions';

export enum PostbackActionTypes {
  LOAD_ALL_TRANSACTIONS_REQUEST = '@postback/LOAD_ALL_TRANSACTIONS_REQUEST',
  LOAD_ALL_TRANSACTIONS_SUCCESS = '@postback/LOAD_ALL_TRANSACTIONS_SUCCESS',
  LOAD_ALL_TRANSACTIONS_FAIL = '@postback/LOAD_ALL_TRANSACTIONS_FAIL',
}

export enum ObjectsPostbacks {
  ORDER = 'order',
  ITEM = 'item',
  TRANSACTION = 'transaction',
  BILLING = 'billing',
  CARD = 'card',
  ADDRESS = 'address',
  CUSTOMER = 'customer',
  DOCUMENT = 'document',
}

export type ObjectItems = {
  object: ObjectsPostbacks.ITEM;
  id: string;
  title: string;
  unit_price: number;
  quantity: number;
  category: string;
  tangible: boolean;
  venue: string;
  date: string;
};

export type ObjectOrder = {
  object: ObjectsPostbacks.ORDER;
  id: string;
  company_id: string;
  status: string;
  amount: string;
  items: ObjectItems[];
  payment_link_id: string;
  postback_url: string;
  date_created: string;
};

export type ObjectDocuments = {
  id: string;
  type: string;
  number: string;
};

export type ObjectCostumer = {
  id: string;
  external_id: string;
  type: string;
  country: string;
  name: string;
  email: string;
  phone_numbers: string[];
  documents: ObjectDocuments[];
};

export type ObjectAddress = {
  street: string;
  complementary: string;
  street_number: string;
  neighborhood: string;
  city: string;
  state: string;
  zipcode: string;
  country: string;
  id: string;
};

export type ObjectBilling = {
  id: string;
  name: string;
  address: ObjectAddress;
};

export type ObjectCard = {
  id: string;
  brand: string;
  holder_name: string;
  first_digits: string;
  last_digits: string;
  country: string;
  valid: string;
  expiration_date: string;
};

export type ObjectDevice = {
  session: string;
};

export type ObjectTransaction = {
  refuse_reason: string;
  status_reason: string;
  date_created: string;
  date_updated: string;
  amount: number;
  paid_amount: string;
  refunded_amount: string;
  installments: string;
  cost: string;
  postback_url: string;
  payment_method: string;
  antifraud_score: string;
  customer: ObjectCostumer;
  billing: ObjectBilling;
  card: ObjectCard;
  fraud_covered: string;
  fraud_reimbursed: string;
  order_id: string;
  risk_level: string;
  receipt_url: string;
  payment: string;
  addition: string;
  pix_qr_code: string;
  pix_expiration_date: string;
};

export type Item = {
  id: string;
  title: string;
  quantity: number;
  category: string;
  unit_price: number;
};

export interface PostbackOrders {
  transaction_id: string;
  event: string;
  fingerprint: string;
  old_status: string;
  desired_status: string;
  current_status: string;
  object: ObjectsPostbacks.ORDER;
  order: ObjectOrder;
}

export type Actions = {
  activeIcon: boolean;
  disabled: boolean;
  iconActive?: OverridableComponent<SvgIconTypeMap>;
  iconInactive?: OverridableComponent<SvgIconTypeMap>;
  onClick?: () => void;
};

export interface PostBackFormattedGrid {
  id: string;
  name?: string;
  cpf?: string;
  companyName?: string;
  instructor?: string;
  items?: Item[];
  phone?: string;
  battery?: string;
  motorcycle?: string;
  emailIsValid: string;
  companion?: string;
  namePayer?: string;
  cpfPayer?: string;
  amountWithoutFees?: string;
  paymentMethod: string;
  amountFormatted?: string;
  current_status?: string;
  amountFeesFormatted?: string;
  installments?: string;
  tableActions: Actions;
}

export interface PostbackTransaction {
  id: string;
  companyName: string;
  current_status: string;
  transaction: ObjectTransaction;
  paymentLinkId: string;
  items: Item[];
  amountFees?: number;
  user: {
    userId: string;
    name?: string;
    email?: string;
    cpf?: string;
    phone?: string;
    emergencyPhone?: string;
    bloodType?: string;
    battery?: string;
    allergy?: string;
    motorcycle?: string;
    instructor?: string;
    companion?: string;
    address?: string;
  };
  logEmail: {
    sended?: boolean;
  };
}

export type PaginationResponse = {
  totalDocs: number;
  pages: number;
  page: number;
};

export type PostbackState = {
  transactions: {
    dataList: GellAllTransactionsPostbackPagination;
    isLoading?: boolean;
    error?: Error;
  };
};

export type GellAllTransactionsPostbackPagination = {
  docs: PostBackFormattedGrid[];
  pagination: PaginationResponse;
};

export type GellAllTransactionsPostback = {
  docs: PostbackTransaction[];
  pagination: PaginationResponse;
};

export type GetAllOrdersPostbackResponse = {
  getAllOrders: PostbackOrders[];
};

export type GetAllTransactionsPostbackResponse = {
  getAllTransactions: GellAllTransactionsPostback;
};

export type DataOrderList = {
  orders: PostbackOrders;
};

export type DataTransactionList = {
  transactions: PostbackTransaction;
};

export type PaginationParams = {
  limit: number;
  page: number;
  companyId?: string;
};

export type PostbackAction = ReturnType<EnumLiteralsOf<typeof actions>>;

export type PostbackReducer = Reducer<PostbackState, PostbackAction>;
