import { combineReducers } from 'redux';

import PostbackReducer from './postback/postbackReducer';
import SendMailReducer from './sendMail/sendMailReducer';
import SessionReducer from './session/sessionReducer';
import linkGeneratorReducer from './linkGenerator/reducer';

const reducers = combineReducers({
  Postback: PostbackReducer,
  SendMail: SendMailReducer,
  Session: SessionReducer,
  LinkGenerator: linkGeneratorReducer,
});

export default reducers;
export type ReducerState = ReturnType<typeof reducers>;
