import {
  GellAllTransactionsPostbackPagination,
  PostbackActionTypes,
} from './types';

const loadAllTransactionsRequest = (payload: {
  limit: number;
  page: number;
  companyId?: string;
}) => ({
  type: PostbackActionTypes.LOAD_ALL_TRANSACTIONS_REQUEST,
  payload: {
    limit: payload.limit,
    page: payload.page,
    companyId: payload && payload.companyId,
  },
});

const loadAllTransactionsSuccess = (payload: {
  data: GellAllTransactionsPostbackPagination;
}) => ({
  type: PostbackActionTypes.LOAD_ALL_TRANSACTIONS_SUCCESS,
  payload: { data: payload.data },
});

const loadAllTransactionsFail = (payload: { error: Error }) => ({
  type: PostbackActionTypes.LOAD_ALL_TRANSACTIONS_FAIL,
  payload: { error: payload.error },
});

const postbackActions = {
  loadAllTransactionsRequest,
  loadAllTransactionsSuccess,
  loadAllTransactionsFail,
};

export default postbackActions;
