import { client } from '../../utils/apolloClient';
import {
  getUserByUserIdQuery,
  searchAllCompaniesQuery,
  createPaymentLinkMutation,
  getTransactionsQuery,
} from './queries';
import {
  CompaniesPromise,
  CreatePaymentLinkPromise,
  GetUserIdPromise,
  TransactionInput,
  TransactionsPromise,
} from './types';

class LinkGeneratorRepository {
  getUserById = async (userId: string): Promise<GetUserIdPromise> => {
    const { data } = await client.query({
      query: getUserByUserIdQuery,
      variables: { userId },
    });

    return data.getUserByUserId;
  };

  searchAllCompanies = async (): Promise<CompaniesPromise> => {
    const { data } = await client.query({
      fetchPolicy: 'network-only',
      query: searchAllCompaniesQuery,
    });

    return data.searchAllCompanies;
  };

  createPaymentLink = async (
    transactionInput: TransactionInput,
  ): Promise<CreatePaymentLinkPromise> => {
    const { data } = await client.mutate({
      mutation: createPaymentLinkMutation,
      variables: { transactionInput },
    });

    return data.createPaymentLink;
  };

  getTransactions = async (): Promise<TransactionsPromise> => {
    const { data } = await client.query({
      fetchPolicy: 'network-only',
      query: getTransactionsQuery,
    });

    return data.getTransactions;
  };
}

const linkGeneratorRepository = new LinkGeneratorRepository();

export default linkGeneratorRepository;
