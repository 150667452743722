import { MessageAlert } from '../global/types';
import { CreateSession, CreateSessionActionTypes } from './types';

const createSessionRequest = (payload: {
  email: string;
  password: string;
  history: any;
}) => ({
  type: CreateSessionActionTypes.CREATE_SESSION_REQUEST,
  payload: {
    email: payload.email,
    password: payload.password,
    history: payload.history,
  },
});

const createSessionSuccess = (payload: { profile: CreateSession }) => ({
  type: CreateSessionActionTypes.CREATE_SESSION_SUCCESS,
  payload: { profile: payload.profile },
});

const createSessionFail = (payload: {
  error?: Error;
  messageAlert: MessageAlert;
}) => ({
  type: CreateSessionActionTypes.CREATE_SESSION_FAIL,
  payload: { error: payload.error, messageAlert: payload.messageAlert },
});

const setCloseMessageAlert = (payload: { closeAlert: boolean }) => ({
  type: CreateSessionActionTypes.MESSAGE_CLOSE_ALERT,
  payload: { closeAlert: payload.closeAlert },
});

const createSessionActions = {
  createSessionRequest,
  createSessionSuccess,
  createSessionFail,
  setCloseMessageAlert,
};

export default createSessionActions;
