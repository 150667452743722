import { client } from '../../utils/apolloClient';
import { createSessionMutation, refreshTokenMutation } from './sessionQueries';
import { CreateSessionResponse } from './types';

class CreateSessionRepository {
  createSession = async (
    email: string,
    password: string,
  ): Promise<CreateSessionResponse> => {
    const { data } = await client.mutate({
      mutation: createSessionMutation,
      variables: { email, password },
    });

    return data.createSession;
  };

  refreshToken = async (token: string): Promise<CreateSessionResponse> => {
    const { data } = await client.mutate({
      mutation: refreshTokenMutation,
      variables: { token },
    });

    return data.refreshToken;
  };
}

const createSessionRepository = new CreateSessionRepository();

export default createSessionRepository;
