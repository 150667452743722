import { client } from '../../utils/apolloClient';
import { sendMailConfirmationMutation } from './sendMailQueries';
import { SendMailResponse } from './types';

class SendMailRepository {
  sendMailConfirmation = async (
    userId: string,
    paymentLinkId: string,
    name: string,
    to: string,
  ): Promise<SendMailResponse> => {
    const { data } = await client.mutate({
      mutation: sendMailConfirmationMutation,
      variables: { userId, paymentLinkId, name, to },
    });

    return data;
  };
}

const sendMailRepository = new SendMailRepository();

export default sendMailRepository;
