import { ApplicationState } from '../types';

import { PostbackState } from './types';

const getState = (state: ApplicationState): PostbackState => state.Postback;

const paginationTransactions = (state: ApplicationState) =>
  getState(state).transactions.dataList.pagination;

const isLoadingTransactions = (state: ApplicationState) =>
  getState(state).transactions.isLoading;

const dataListTransactions = (state: ApplicationState) =>
  getState(state).transactions.dataList;

export default {
  getState,
  isLoadingTransactions,
  dataListTransactions,
  paginationTransactions,
};
