/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isAuthenticated } from '../utils/helpers';

const RouteWrapper = (route: any) => {
  if (route.isPrivate) {
    if (isAuthenticated()) {
      return (
        <Route
          exact={route.exact}
          path={route.path}
          render={props => <route.component {...props} routes={route.routes} />}
        />
      );
    }
    return <Redirect to={{ pathname: '/signin' }} />;
  }

  if (route.path === '/signin' && isAuthenticated()) {
    return <Redirect to={{ pathname: '/dashboard/report' }} />;
  }

  return (
    <Route
      exact={route.exact}
      path={route.path}
      render={props => <route.component {...props} routes={route.routes} />}
    />
  );
};

export default RouteWrapper;
