import { call, put, takeLatest } from 'redux-saga/effects';
import { MessageAlertTypes } from '../global/types';
import sessionActions from './sessionActions';
import sessionRepository from './sessionRepository';
import { storeAuthRefreshToken, storeAuthToken } from '../../utils/helpers';
import { SessionAction, CreateSessionActionTypes } from './types';

export function* createSessionRequest(action: SessionAction): any {
  const { payload } = action as ReturnType<
    typeof sessionActions.createSessionRequest
  >;

  try {
    if (!payload) return;

    const { email, password, history } = payload;

    const session = yield call(
      sessionRepository.createSession,
      email,
      password,
    );

    yield call(storeAuthToken, session.token);
    yield call(storeAuthRefreshToken, session.refreshToken);

    yield call(history.push, { pathname: '/dashboard/report' });

    yield put(
      sessionActions.createSessionSuccess({
        profile: session,
      }),
    );
  } catch (error: any) {
    yield put(
      sessionActions.createSessionFail({
        error: error.message,
        messageAlert: {
          message: error.message,
          type: MessageAlertTypes.ERROR,
          open: true,
        },
      }),
    );
  }
}

const createSessionSagas = [
  takeLatest(
    CreateSessionActionTypes.CREATE_SESSION_REQUEST,
    createSessionRequest,
  ),
];

export default createSessionSagas;
