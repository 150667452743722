import _ from 'lodash';
import actions from './sessionActions';
import {
  SessionReducer,
  SessionState,
  SessionAction,
  CreateSessionActionTypes,
} from './types';

export const INITIAL_STATE: SessionState = {
  profile: {
    _id: '',
    email: '',
    name: '',
    roles: [],
    token: '',
    refreshToken: '',
  },
  isLoading: false,
  error: undefined,
  messageAlert: {
    message: '',
    type: undefined,
    open: false,
  },
};

const createSessionRequest: SessionReducer = state => ({
  ...state,
  confirmation: {
    isLoading: true,
  },
});

const createSessionSuccess: SessionReducer = (state, action) => {
  const { payload } = action as ReturnType<typeof actions.createSessionSuccess>;

  return {
    ...state,
    profile: payload.profile,
    isLoading: false,
  };
};

const createSessionFail: SessionReducer = (state, action) => {
  const { payload } = action as ReturnType<typeof actions.createSessionFail>;

  return {
    ...state,
    isLoading: false,
    error: payload.error,
    messageAlert: payload.messageAlert,
  };
};

const setCloseMessageAlert: SessionReducer = (state, action) => {
  const { payload } = action as ReturnType<typeof actions.setCloseMessageAlert>;

  return {
    ...state,
    messageAlert: {
      message: '',
      type: undefined,
      open: payload.closeAlert,
    },
  };
};

const createSessionReducers = {
  [CreateSessionActionTypes.CREATE_SESSION_REQUEST]: createSessionRequest,
  [CreateSessionActionTypes.CREATE_SESSION_SUCCESS]: createSessionSuccess,
  [CreateSessionActionTypes.CREATE_SESSION_FAIL]: createSessionFail,
  [CreateSessionActionTypes.MESSAGE_CLOSE_ALERT]: setCloseMessageAlert,
};

const sessionReducer = (
  state: SessionState = INITIAL_STATE,
  action: SessionAction,
): SessionState => {
  const reducer = _.find(
    createSessionReducers,
    (_value, key) => key === action.type,
  );

  if (reducer) return reducer(state, action);

  return state;
};

export default sessionReducer;
